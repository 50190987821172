<template>
  <div class="jk-bg-wrapper">
    <div class="jk-wrapper">
      <div class="jk-header _img">
        <div class="_back" @click="$router.go(-1)"><img src="../status/img/icon_back.png" /></div>
        <div class="_img">
          <img  :src="backImage" />
          <!--          博鳌国际医院-->
          <span v-if="subGoods==19" class="boao_1" @click="get_sub_goods_info(19)" ></span>
          <span v-if="subGoods==20" class="boao_2" @click="get_sub_goods_info(20)"></span>
          <span v-if="subGoods==21" class="boao_3" @click="get_sub_goods_info(21)"></span>
        </div>
      </div>
    </div>

    <!-- 确定预约 -->
    <div class="dialog-wrapper" v-if="showSureDialog">
      <div class="_content">
        <div class="_window">
          <div class="_title _img"><img src="../status/img/dialog_title1.png" /></div>
          <div class="_list">
            <div class="_xiangmu">
              <div class="_h1">预约项目</div>
              <div class="_h2">{{subGoodsInfo.title}}</div>
            </div>
            <div class="_dijian">
              <van-row>
                <van-col span="8">消耗积点</van-col>
                <van-col span="16"><span class="_hd">{{subGoodsInfo.price}}积点/{{subGoodsInfo.inventory_unit}}</span></van-col>
              </van-row>
            </div>
          </div>
          <div class="_btn"><img src="../status/img/dialog_btn.png" @click="onClickSureSignup" /></div>
        </div>
        <div class="_closed" @click="showSureDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>

    <!-- 预约成功 -->
    <div class="dialog-wrapper" v-if="showOkDialog">
      <div class="_content">
        <div class="_window">
          <div class="_title _img"><img src="../status/img/dialog_title_ok.png" /></div>
          <div class="_status"><img src="../status/img/btn_ok.png" /></div>
          <div class="_tips">您的专属客户经理或服务供应商<br>会在稍后与您取得联系，请保持电话畅通。</div>
          <div class="_list">
            <div class="_dijian">
              <van-row>
                <van-col span="8">消耗积点</van-col>
                <van-col span="16"><span class="_hd">{{subGoodsInfo.price}}积点</span></van-col>
              </van-row>
            </div>
            <div class="_dijian">
              <van-row>
                <van-col span="8">剩余积点</van-col>
                <van-col span="16"><span class="_hd">{{userInfo.integral}}积点</span></van-col>
              </van-row>
            </div>
          </div>
          <div class="_btn"><img src="../status/img/dialog_btn.png" @click="showOkDialog = false" /></div>
        </div>
        <div class="_closed" @click="showOkDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>

    <!-- 预约失败 -->
    <div class="dialog-wrapper" v-if="showFalseDialog">
      <div class="_content">
        <div class="_window">
          <div class="_title _img"><img src="../status/img/dialog_title_false.png" /></div>
          <div class="_status"><img src="../status/img/btn_false.png" /></div>
          <div class="_tips">{{error_msg}}</div>
          <div class="_list">
            <div class="_dijian">
              <van-row>
                <van-col span="8">所需积点</van-col>
                <van-col span="16"><span class="_hd">{{subGoodsInfo.price}}积点</span></van-col>
              </van-row>
            </div>
            <div class="_dijian">
              <van-row>
                <van-col span="8">剩余积点</van-col>
                <van-col span="16"><span class="_hd">{{userInfo.integral}}积点</span></van-col>
              </van-row>
            </div>
          </div>
          <div class="_btn"><img src="../status/img/dialog_btn.png" @click="showFalseDialog = false" /></div>
        </div>
        <div class="_closed" @click="showFalseDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>
    <Footer :step="2" />
  </div>
</template>

<script>

import Cookies from "js-cookie";
import Footer from './components/footer'
export default {
  mixins: [],
  components: {Footer},
  data() {
    return {
      showDayDialog: false,
      showSureDialog: false,
      showOkDialog: false,
      showFalseDialog: false,
      showImage:"",
      show_submit_btn:true,
      subGoodsInfo:{},
      picId:"",
      subGoods:"",
      goodsInfo:{},
      goodsId:"",
      backImage:"",
      userInfo:{},
      error_msg:""
    };
  },

  created() {
    this.picId = this.$route.query.picId;
    this.subGoods = this.$route.query.subGoods;
    this.goodsId = this.$route.query.goodsId;
  },

  mounted() {
    this.getResoueceById(this.picId);
    this.getGoodsInfo();
    this.getMyInfo();
  },

  methods: {
    async getMyInfo() {
      // 获取分类列表
      let {data,code } = await this.$ajax.gateway("/api/api_get_my_info", {
        "METHOD": "get"
      });
      if(code!=200){
        Cookies.remove('admin_hdbank_test_token');
        this.$router.push("/login")
      }else{
        this.userInfo=data;
      }
    },
    async getGoodsInfo() {
      let {data, code} = await this.$ajax.gateway("/api/api_get_goods_detail", {
        "METHOD": "get",
        id: this.goodsId
      });
      this.goodsInfo = data;
    },
   async getResoueceById(id){
      // 获取资源图片地址
      let {data,code} = await this.$ajax.gateway("/api/api_get_resource_by_id", {
        "METHOD": "get",
        "id": id
      });
      if(code==200){
        this.backImage = data.pic_url;
      }

    },
    async get_sub_goods_info(id){
      console.info(id);
      // 获取子商品详情
      let {data} = await this.$ajax.gateway("/api/api_get_sub_goods_detail_by_id", {
        "METHOD": "get",
        "id": id
      });
      this.subGoodsInfo = data;
      this.showSureDialog=true;
    },
    // 行程安排
    onClickDayTips() {
      if(this.goodsInfo.id==2){
        this.showDayDialog = true;
      }
    },

    // 预约
    onClickSignup() {
      this.showSureDialog = true;
    },

    // 确认预约
    onClickSureSignup() {
      this.showSureDialog = false;
      this.submit_order(this.goodsId,this.subGoods,"");
    },
    async submit_order(goods_id,sub_goods_id,bookings_date){
      this.showDayDialog = false;
      if(this.userInfo.integral<this.goodsInfo.sub_goods[0].price){
        this.error_msg="积分不够" ;
        this.showFalseDialog=true;
        return;
      }
      // 用户预约
      let {data,code} = await this.$ajax.gateway("/api/api_buy_goods", {
        ERROR_BACK:true,
        "body": {
          "sub_goods_id": sub_goods_id,
          "bookings_date": bookings_date,
          "goods_id":goods_id
        }
      });
      if(code==200){
        this.getMyInfo();
        this.showSureDialog=false;
        this.showOkDialog = true;
      }else{
        this.orderInfo=data;
        this.showFalseDialog=true;
      }
    },

  }
};
</script>

<style lang="less" scoped>
  @import "../status/css/jk.less";
  .boao_1{
    position: absolute;
    height:1.5rem;
    width: 6rem;
    z-index: 999;
    left: 2rem;
    top:31.5rem;
  }
  .boao_2{
    position: absolute;
    height:1.5rem;
    width: 6rem;
    z-index: 999;
    left: 2rem;
    top:28.5rem;
  }
  .boao_3{
    position: absolute;
    height:1.5rem;
    width: 6rem;
    z-index: 999;
    left: 2rem;
    top:25.2rem;
  }
  .jk-header{
    //特殊按钮标记
    .show_pic_back_btn{
      position: absolute;
      height: 1.1rem;
      width: 3rem;
      z-index: 999;
      left: 0rem;
      top:0.6rem;
    }

  }

  .dialog-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
    z-index: 100;
    ._content {
      width: 100%;
      position: absolute;
      top: 2rem;
      left: 0;
      ._window {
        margin: 0 0.7rem;
        padding: 0.8rem 0.8rem 0.4rem;
        background: url("../status/img/dialog_bg.png") repeat-x left center;
        background-size: auto 100%;
        border: 3px solid #CCAD8A;
        border-radius: 0.2rem;
        min-height: 20vh;
        max-height: 70vh;
        overflow-y: scroll;
        img {
          max-width: 100%;
        }

        ._title {
          margin-bottom: 1rem;
        }
        ._status {
          text-align: center;
          margin-top: -0.8rem;
          img {
            width: 1.8rem;
          }
        }
        ._tips {
          width: 110%;
          margin-left: -5%;
          padding: 0.1rem 0 0.5rem;
          font-size: 0.36rem;
          color: #c8b489;
          text-align: center;
        }
        ._list {
          ._xiangmu {
            color: #c8b489;
            text-align: center;
            border: 2px solid #CCAD8A;
            border-radius: 0.2rem;
            padding-bottom: 0.2rem;
            ._h1 {
              font-size: 0.54rem;
              padding: 0.15rem 0;
            }
            ._h2 {
              font-size: 0.4rem;
              padding: 0.2rem 0;
              background: #CCAD8A;
              border-radius: 0.1rem;
              color: #a71b1e;
              margin: 0 0.2rem;
            }
          }
          ._dijian {
            color: #c8b489;
            text-align: center;
            border: 2px solid #CCAD8A;
            border-radius: 0.2rem;
            padding: 0.2rem;
            line-height: 0.8rem;
            margin-top: 0.3rem;
            font-size: 0.36rem;
            span._hd {
              display: block;
              background: #CCAD8A;
              border-radius: 0.1rem;
              color: #a71b1e;
              font-weight: 600;
              font-size: 0.48rem;
            }
          }
        }
        ._btn {
          text-align: center;
          padding: 0.7rem 0 0;
          img {
            max-width: 110%;
            margin-left: -5%;
          }
        }

        &._window_w {
          padding: 0.2rem;
          ._title {
            padding-top: 0.3rem;
            margin-bottom: 0;
          }
        }
      }
      ._closed {
        padding: 0.4rem 0;
        text-align: center;
        img {
          width: 1.2rem;
        }
      }
    }
  }
</style>
